import React, { Component } from 'react';
import ReactDOM from 'react-dom';
import SweetAlert from 'react-bootstrap-sweetalert';
import CountUp from 'react-countup';


import _ from "lodash";

import axios from 'axios';
import { AgGridReact } from '@ag-grid-community/react';
import {AllModules} from '@ag-grid-enterprise/all-modules';
//import {ChartMenu} from '@ag-grid-enterprise/charts/dist/es6/chartAdaptor/chartComp/menu/chartMenu';

import "@ag-grid-enterprise/all-modules/dist/styles/ag-grid.css";
import "@ag-grid-enterprise/all-modules/dist/styles/ag-theme-balham.css";
//import Grid from '../layouts/gridnosidebarComponent';


import 'react-vertical-timeline-component/style.min.css';

import Breadcrumb from '../common/breadcrumb.component';
var redirectURL = require('../redirectURL');
var googleAnalytics = require("../common/googleanalytics");


export default class Changepassword extends Component {

	constructor(props){
		super(props);
		this.state={
			pageTitle: "Change Password",
			eventGridAction: "gridAction",
			eventCounterAction: "counterAction",
			eventFormAction: "formAction",
			currentPassword: null,
			newPassword: null,
			confirmPassword: null,
			errorMessage : false,
			showPassword : false,
			showPassword1 : false,
			showPassword2 : false,
			messageFromServer: "",
			uploadDivWidth:'0%',
			sliderTranslate:'',
			sliderRouteTranslate:'',
			tabsliderTranslate:'',
			timelineinfo:'',
			consignment_code:'',
			mapinfo:'',
			loadshow:'show-n',
			showDiv:'show-n',
			alertshow:'fade',
			alertmg:'show-n',
			alerterrmg:'show-n',
			alerterrshow:'fade',
			overly:'show-n',
			alert:null,
            show: false,
            basicTitle:'',
			basicType:"default",
			modules: AllModules,
			filterstatess:{},
      	    columnsdatas:{},
      	    rowgroupdcols:[],
      	    valuecolumns:[],
      	    pivotmodeis:false,
			pivotcols:[],
			defTransitCoords:'',
			allRowData:0,
			highseascount:0,
			inportcount:0,
			

			inicdcount:0,
			transitmsilcount:0,
      	    defaultColDef: {
      	    	sortable: true,
				filter: true,
				resizable: true,
				

      	    },
      	    rowData: null,
			headerHeight: 40,
			groupHeaderHeight:60,
      	    rowModelhighchartoptionsType: 'enterprise',
			rowGroupPanelShow: 'always',


      	    animateRows: true,
      	    debug: true,
      	    showToolPanel: false,
			uppressAggFuncInHeader: true,
				
			childconfs: [],
			childrow:[],
			rowModelType: "serverSide",
			paginationPageSize:100,
			cacheBlockSize: 10,
			maxBlocksInCache: 1,
			statusBar: {
				statusPanels: [
					{
					statusPanel: "agTotalAndFilteredRowCountComponent",
					align: "left"
					},
					{
					statusPanel: "agTotalRowCountComponent",
					align: "center"
					},
					{ statusPanel: "agFilteredRowCountComponent" },
					{ statusPanel: "agSelectedRowCountComponent" },
					{ statusPanel: "agAggregationComponent" }
				]
			},
			sideBar: {
				toolPanels: [
					{
					id: "columns",
					labelDefault: "Columns",
					labelKey: "columns",
					iconKey: "columns",
					toolPanel: "agColumnsToolPanel"
					},
					{
					id: "filters",
					labelDefault: "Filters",
					labelKey: "filters",
					iconKey: "filter",
					toolPanel: "agFiltersToolPanel"
					}
				]
			},

				
			
		}
	}

	logPageView = () => {
		try {
			if (googleAnalytics.page.enableGA) {
				googleAnalytics.logPageView();
				let eventGridAction = googleAnalytics.page.actionType.gridAction + " - " + window.location.pathname;
				let eventCounterAction = googleAnalytics.page.actionType.counterAction + " - " + window.location.pathname;
				let eventFormAction = googleAnalytics.page.actionType.formAction + " - " + window.location.pathname;
				this.setState({eventGridAction: eventGridAction, eventCounterAction: eventCounterAction, eventFormAction: eventFormAction});
			} else {
				console.log("GA disabled...");
			}
		} catch(error) {
			console.error("Error occured while logging to GA, error = ", error);
		}
	}

	componentDidMount(){
		this.logPageView(); // Log page view to GA
	};
	FormHandler(e){
		console.log("In ChangePassword, formhandler, e = ", e);
		e.preventDefault();

		if (googleAnalytics.page.enableGA) {
			let pageTitle = this.state.pageTitle;
			let eventOptions = {
				"category": pageTitle,
				"action": this.state.eventFormAction,
				"label": googleAnalytics.page.action.formSubmittedChangePassword,
			}
			googleAnalytics.logEvent(eventOptions);
		}

		let currentPassword = this.state.currentPassword;
		let newPassword = this.state.newPassword;
		let confirmPassword = this.state.confirmPassword;

		if(!currentPassword || !newPassword || !confirmPassword) {
			this.setState({errorMessage: `All <span style="color: red;">*</span> fields are required.`});
			return false;
		}

		if (newPassword) {
			const newPasswordError = this.validateNewPassword(newPassword);
			if (!newPasswordError) {
				return; // Stop the submission if new password is invalid
			}
			else if (newPassword !== confirmPassword) {
				this.setState({ errorMessage: "New and Confirm Password should be same." });
				return false;
			}
		}

		const layoutdata={
			token:localStorage.getItem('token'),
			email:localStorage.getItem('email'),
			userId:localStorage.getItem('userid'),
			currentPassword:currentPassword,
			newPassword: newPassword,
			confirmPassword: confirmPassword
		}
		redirectURL.post('/masters/changepassword', layoutdata,{
			headers:{
				"Access-Control-Allow-Origin": "*",
				"Access-Control-Allow-Headers": "Origin, X-Requested-With, Content-Type, Accept"
			}
		})
  		.then((response) => {
	  		// console.log(response);
			var records = JSON.parse(JSON.stringify(response)).data;
			//console.log("Records = ", records);
			if (records.status == "success") {
				this.setState({
					show: true,
					basicTitle: 'Password updated successfully.',
					basicType: "success",
				})
			}
			if (records.status == "error") {
				let message = (records.message) ? records.message : "Password updated failed. Please try again";
				this.setState({errorMessage: message})
			}
			e.preventDefault();
  		})
  		.catch(function (error) {
  		    console.log(error);
  		});



	}

	closeAlert = async () => {
		this.setState({
			show: false
		});
		await this.logOut();
		console.log("check_heree")
	}

	togglePasswordVisibility = (item) => {
		this.setState(prevState => {
			// Only update the state if the new state is different
			if (item === "current" && prevState.showPassword !== !prevState.showPassword) {
				return { showPassword: !prevState.showPassword };
			}
			if (item === "new" && prevState.showPassword1 !== !prevState.showPassword1) {
				return { showPassword1: !prevState.showPassword1 };
			}
			if (item === "confirm" && prevState.showPassword2 !== !prevState.showPassword2) {
				return { showPassword2: !prevState.showPassword2 };
			}
			return null;
		});
	};
	

	logOut = async (e) => {
		var userloggparams = {
			token: localStorage.getItem('token'),
			userId: localStorage.getItem('userid'),
			logoutype: "manual"
		}
		await redirectURL.post("/manage/removeloggeduser", userloggparams)
			.then((resp) => {
				console.log("resp ", resp)
			})

		if (googleAnalytics.page.enableGA) {
			let pageTitle = "Logout";
			let formAction = googleAnalytics.page.actionType.miscAction;
			let eventOptions = {
				"category": pageTitle,
				"action": formAction,
				"label": googleAnalytics.page.action.userLogout,
			}
			googleAnalytics.logEvent(eventOptions);
		}
		var loginType = localStorage.getItem("is_enmovil_transporter");
		await localStorage.removeItem('token');
		await localStorage.removeItem('userid');
		await localStorage.removeItem('dept_code');
		await localStorage.removeItem('firstname');
		await localStorage.removeItem('lastname');
		await localStorage.removeItem('email');
		await localStorage.removeItem('is_admin');
		await localStorage.removeItem('is_global');
		await localStorage.removeItem('is_dept_admin');
		await localStorage.removeItem('usermenucodes');
		await localStorage.removeItem('loading_port_code');
		await localStorage.removeItem('loadingPointName');
		await localStorage.clear();
		sessionStorage.setItem('ref', null);
		//localStorage.setItem('menuText',[]);
		await delete axios.defaults.headers.common['Authorization'];
		this.setState({
			redirectToReferrer: ''
		});
		console.log("logout successfully", localStorage.getItem('token'));


		await relaodpage(loginType);
	}

	changeHandler(event){
		let name = event.target.name;
		let value = event.target.value;
		//console.log("name and value = ", name, value);
		this.setState({[name]:value,errorMessage:""});
	}

	validateNewPassword(password) {
		const passwordLength = password.length >= 8; // Minimum 8 characters
		const containsUppercase = /[A-Z]/.test(password); // At least one uppercase letter
		const containsLowercase = /[a-z]/.test(password); // At least one lowercase letter
		const containsNumber = /\d/.test(password); // At least one number
		const containsSpecialChar = /[!@#$%^&*(),.?":{}|<>]/.test(password); // At least one special character

		if (!passwordLength) {
			this.setState({ errorMessage: "New password must be at least 8 characters." });
			return false;
		}
		if (!containsUppercase) {
			this.setState({ errorMessage: "New password must contain at least one uppercase letter." });
			return false;
		}
		if (!containsLowercase) {
			this.setState({ errorMessage: "New password must contain at least one lowercase letter." });
			return false;
		}
		if (!containsNumber) {
			this.setState({ errorMessage: "New password must contain at least one numeric digit." });
			return false;
		}
		if (!containsSpecialChar) {
			this.setState({ errorMessage: "New password must contain at least one special character." });
			return false;
		}

		// If all checks pass, clear any existing error and return true
		this.setState({ errorMessage: false });
		return true;
	}

	
	render(){
		return(
			<div className="container-fluid">
				<Breadcrumb parent="Profile" title="Change Password" />
			<SweetAlert
	            show={this.state.show}
	            type={this.state.basicType}
	            title={this.state.basicTitle}
	            onConfirm={this.closeAlert}
	        >
	        </SweetAlert>
	            {this.state.alert}

            	<div className="row">					
            		<div className="col-xl-12 col-lg-12">
		            	<div className="card">
                            <div className="card-header">
				       			<h5>
				       				<i className="icofont icofont-ui-password cus-i"></i> <span>{this.state.pageTitle} </span>
								</h5>
				   			</div>				   			
		            		<div className="card-body">
								
								 <div id="myGrid" style={{ height: "580px",width: "100%"}}  className="ag-theme-balham">
									<form className=" theme-form col-xl-12 col-lg-12" onSubmit={this.FormHandler.bind(this)}>
										<div className="col-xl-4 col-lg-6 col-sm-12">
											{this.state.errorMessage ?
												<div className="txt-danger f15" style={{ padding: '0px 0px 20px 0px' }}> {this.state.errorMessage}</div>
												: ""
											}

										</div>
										<div className="col-xl-4 col-lg-6 col-sm-12" style={{ paddingRight: '0px' }}>
											<label>Current Password <span style={{ color: "red" }}>*</span></label>
											<div className="password-input-container">
												<input type={this.state.showPassword ? "text" : "password"} placeholder="Current Password" name="currentPassword"
													className="form-control rselect"
													onChange={this.changeHandler.bind(this)} required />
												<button
													type="button"
													onClick={() => this.togglePasswordVisibility("current")}
													className="eye-icon-button"
													aria-label="Toggle password visibility"
												>
													{this.state.showPassword ? "👁️" : "👁️‍🗨️"}
												</button>
											</div>
										</div>


										<div className="col-xl-4 col-lg-6 col-sm-12" style={{ paddingRight: '0px' }}>
											<label>New Password <span style={{ color: "red" }}>*</span></label>
											<div className="password-input-container">
												<input type={this.state.showPassword1 ? "text" : "password"} placeholder="New Password" name="newPassword"
													className="form-control rselect"
													onChange={this.changeHandler.bind(this)} required />
												<button
													type="button"
													onClick={() => this.togglePasswordVisibility("new")}
													className="eye-icon-button"
													aria-label="Toggle password visibility"
												>
													{this.state.showPassword1 ? "👁️" : "👁️‍🗨️"}
												</button>
											</div>
										</div>


										<div className="col-xl-4 col-lg-6 col-sm-12" style={{ paddingRight: '0px' }}>
											<label>Confirm Password <span style={{ color: "red" }}>*</span></label>
											<div className="password-input-container">
												<input type={this.state.showPassword2 ? "text" : "password"} placeholder="Confirm Password" name="confirmPassword"
													className="form-control rselect"
													onChange={this.changeHandler.bind(this)} required />
												<button
													type="button"
													onClick={() => this.togglePasswordVisibility("confirm")}
													className="eye-icon-button"
													aria-label="Toggle password visibility"
												>
													{this.state.showPassword2 ? "👁️" : "👁️‍🗨️"}
												</button>
											</div>
										</div>

										<div className="col-xl-12 col-lg-12 col-sm-12" style={{ padding: "0" }}>
											<button name="changePassword" id="go" style={{ height: "38px", margin: "10px 15px" }}
												className="btn btn-primary" type="submit">Change Password</button>
										</div>
										<p style={{ color: "red", marginTop: "70px" }}><strong>Note:</strong> Password must be at least 8 characters, with one uppercase letter, one numeric digit, and one special character.</p>
									</form>



									<div className={"dataLoadpage " +(this.state.loadshow)}>
									</div>
									<div className={"dataLoadpageimg " +(this.state.loadshow)}>
										<div className="loader-box"><div className="loader-box"><div className="rotate dashed colored"></div></div></div>
									</div>

		          		        </div>
				   			</div>
				   		</div>	
				   	</div>
				 </div>
				
				
            </div>
              	
		);
    }
}

function relaodpage(loginType){
    if(loginType == 1)
    {
        window.location.href="/tptlogin";
    }
    else
    {
        window.location.reload();
    }
}
