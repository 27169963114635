import React, { useState, useRef } from 'react';
import redirectURL from '../redirectURL';
import SweetAlert from 'react-bootstrap-sweetalert';

const FileUpload = () => {
    // Move `styles` object to the top to ensure it's available when referenced
    const styles = {
        container: {
            width: '55%',
            margin: '20px auto',
            padding: '20px',
            border: '1px solid #ccc',
            borderRadius: '8px',
            textAlign: 'center',
            backgroundColor: '#f9f9f9',
        },
        input: {
            margin: '10px 5px',
            padding: '10px',
            fontSize: '16px',
            borderRadius: '5px',
            border: '1px solid #ccc',
        },
        button: {
            padding: '10px 20px',
            backgroundColor: '#007BFF',
            color: 'white',
            border: 'none',
            borderRadius: '5px',
            fontSize: '16px',
            cursor: 'pointer',
            transition: 'background-color 0.3s ease',
            marginLeft: '6px',
        },
        buttonHover: {
            backgroundColor: '#0056b3',
        },
        errorMessage: {
            color: 'red',
            fontSize: '14px',
            marginTop: '10px',
            fontWeight: 'bold',
        },
    };
    const fileInputRef = useRef(null);
    const [file, setFile] = useState(null);
    const [error, setError] = useState('');
    const [state, setState] = useState({
        basicTitle: '',
        basicType: '',
        show: false,
        loadshow: 'hide-n',
        overly: 'hide-n'
      });
    const [buttonStyle, setButtonStyle] = useState(styles.button);

    const handleFileChange = (event) => {
        const uploadedFile = event.target.files[0];

        if (!uploadedFile) {
            setError('Please upload a valid file.');
            return;
        }

        if (uploadedFile.type !== 'application/json') {
            setError('Please upload a valid JSON file.');
            setFile(null);
            // Clear the file input field
            if (fileInputRef.current) {
                fileInputRef.current.value = '';
            }
            return;
        }

        const maxSize = 5 * 1024 * 1024; // 5MB
        if (uploadedFile.size > maxSize) {
            setError('File size exceeds 5MB. Please upload a smaller file.');
            setFile(null);
            // Clear the file input field
            if (fileInputRef.current) {
                fileInputRef.current.value = '';
            }
            return;
        }

        const reader = new FileReader();
        reader.onload = (e) => {
            try {
                const jsonContent = JSON.parse(e.target.result);

                // Validate `tripSheets` structure
                if (
                    !jsonContent.tripSheets ||
                    !Array.isArray(jsonContent.tripSheets) ||
                    jsonContent.tripSheets.length === 0
                ) {
                    setError('Missing or invalid required field: tripSheets');
                    setFile(null);
                    // Clear the file input field
                    if (fileInputRef.current) {
                        fileInputRef.current.value = '';
                    }
                    return;
                }

                for (const tripSheet of jsonContent.tripSheets) {
                    if (!tripSheet.userGstin) {
                        setError('Missing required field: userGstin in tripSheets');
                        setFile(null);
                        // Clear the file input field
                        if (fileInputRef.current) {
                            fileInputRef.current.value = '';
                        }
                        return;
                    }
                    // if (!tripSheet.vehicleNo) {
                    //     setError('Missing required field: vehicleNo in tripSheets');
                    //     setFile(null);
                    //     return;
                    // }
                    if (!tripSheet.transDocDate) {
                        setError('Missing required field: transDocDate in tripSheets');
                        setFile(null);
                        // Clear the file input field
                        if (fileInputRef.current) {
                            fileInputRef.current.value = '';
                        }
                        return;
                    }
                    if (!tripSheet.fromPlace) {
                        setError('Missing required field: fromPlace in tripSheets');
                        setFile(null);
                        // Clear the file input field
                        if (fileInputRef.current) {
                            fileInputRef.current.value = '';
                        }
                        return;
                    }
                    if (!tripSheet.transMode) {
                        setError('Missing required field: transMode in tripSheets');
                        setFile(null);
                        // Clear the file input field
                        if (fileInputRef.current) {
                            fileInputRef.current.value = '';
                        }
                        return;
                    }
                    if (!tripSheet.fromState) {
                        setError('Missing required field: fromState in tripSheets');
                        setFile(null);
                        // Clear the file input field
                        if (fileInputRef.current) {
                            fileInputRef.current.value = '';
                        }
                        return;
                    }
                    if (
                        !tripSheet.tripSheetEwbBills ||
                        !Array.isArray(tripSheet.tripSheetEwbBills) ||
                        tripSheet.tripSheetEwbBills.length === 0
                    ) {
                        setError('Missing or invalid required field: tripSheetEwbBills in tripSheets');
                        setFile(null);
                        // Clear the file input field
                        if (fileInputRef.current) {
                            fileInputRef.current.value = '';
                        }
                        return;
                    }

                    for (const bill of tripSheet.tripSheetEwbBills) {
                        if (!bill.ewbno) {
                            setError('Missing required field: ewbno in tripSheetEwbBills');
                            setFile(null);
                            // Clear the file input field
                            if (fileInputRef.current) {
                                fileInputRef.current.value = '';
                            }
                            return;
                        }
                    }
                }

                // If all validations pass
                setFile(uploadedFile);
                setError('');
            } catch (err) {
                setError('Invalid JSON structure.');
                setFile(null);
                // Clear the file input field
                if (fileInputRef.current) {
                    fileInputRef.current.value = '';
                }
            }
        };

        reader.readAsText(uploadedFile);
    };

    const handleSubmit = (event) => {
        event.preventDefault();
        if (!file) return;
        var transportercode = JSON.parse(localStorage.getItem("transportercode"));

        const formData = new FormData();
        formData.append('jsonFile', file);
        formData.append('transporter_code', transportercode[0]);
        redirectURL
            .post('/consignments/bulkUploadConsolidate', formData)
            .then((response) => {
                const data = response.data;
                var respMsg = "";
                var basicType = "";
                if (data.status == 'Success') {
                    respMsg = 'File uploaded and processed successfully';
                    basicType = "success"
                } else {
                    respMsg = data.message || 'An error occurred while uploading the file';
                    basicType = "error"
                }
                setState({
                    basicTitle: respMsg,
                    basicType: basicType,
                    show: true,
                    loadshow: 'show-n',
                    overly: 'show-n'
                });
                setFile(null);
                // Clear the file input field
                if (fileInputRef.current) {
                    fileInputRef.current.value = '';
                }
            })
            .catch((error) => {
                console.error('Error:', error);
                setState({
                    basicTitle: 'An error occurred while uploading the file',
                    basicType: 'error',
                    show: true,
                    loadshow: 'show-n',
                    overly: 'show-n'
                });
                setFile(null);
                // Clear the file input field
                if (fileInputRef.current) {
                    fileInputRef.current.value = '';
                }
            });
    };
    const closeAlert = () => {
        setState({ show: false });
        // Uncomment the following line if you want to redirect:
        // window.location.href = "/tptlogin/ewaybillrequests";
    };

    const handleMouseEnter = () => setButtonStyle({ ...styles.button, ...styles.buttonHover });
    const handleMouseLeave = () => setButtonStyle(styles.button);

    return (
        <div className="container-fluid">
            {state.show && ( // Render SweetAlert only if show is true
            <SweetAlert
                show={state.show}
                type={state.basicType}
                title={state.basicTitle}
                onConfirm={closeAlert}
            />
            )}
            <div style={styles.container}>
                <h6>Consolidated EWB - Bulk Upload</h6>
                <form onSubmit={handleSubmit}>
                    <label>UPLOAD JSON FILE (MAX 5MB)</label>
                    <input
                        type="file"
                        accept=".json"
                        ref={fileInputRef}
                        onChange={handleFileChange}
                        style={styles.input}
                    />
                    {error && <div style={styles.errorMessage}>{error}</div>}
                    <button
                        type="submit"
                        style={buttonStyle}
                        onMouseEnter={handleMouseEnter}
                        onMouseLeave={handleMouseLeave}
                    >
                        Upload & Generate
                    </button>
                </form>
            </div>
        </div>
    );
};

export default FileUpload;