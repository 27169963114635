import React, { useState, useEffect } from "react";
import redirectURL from "../redirectURL";
import SweetAlert from "react-bootstrap-sweetalert";
import EbillWayDetails from './ewaybilldetails.js';

const UpdateEwaybillTransporter = () => {
  const [ewayBillNo, setEwayBillNo] = useState("");
  const [transporterId, setTransporterId] = useState("");
  const [state, setState] = useState({
    basicTitle: "",
    basicType: "",
    show: false,
    tptDetails: [],
    ewayBillDetails: [],
  });

  useEffect(() => {
    const fetchTransporterDetails = async () => {
      const params = { tpt_code: localStorage.getItem("transportercode") };
      try {
        const response = await redirectURL.post(
          "/dashboard/getTransporterDetails",
          params
        );
        if (response?.data?.length > 0) {
          setState((prevState) => ({
            ...prevState,
            tptDetails: response.data,
          }));
        }
      } catch (error) {
        console.error("Error fetching transporter details:", error);
      }
    };
    fetchTransporterDetails();
  }, []);

  const handleGoClick = async () => {
    if (!ewayBillNo) {
      setState((prevState) => ({
        ...prevState,
        basicTitle: "Please enter a valid E-Way Bill Number",
        basicType: "danger",
        show: true,
      }));
      return;
    }

    const formData = {
      eway_bill_number: ewayBillNo,
      tpt_code: JSON.parse(localStorage.getItem("transportercode")),
    };

    const tptDetails = state.tptDetails;
    if (Array.isArray(tptDetails) && tptDetails.length > 0) {
      formData.gstin_of_generator = tptDetails[0].gstin;
    }

    try {
      const response = await redirectURL.post(
        "/consignments/fetchWayBillData",
        formData
      );
      const data = response.data;
      console.log(data.status, "data---")
      if (data.status === 200 || data.status === "200") {
        const ewayBillDetails = data.ewayBillData || [];
        setState((prevState) => ({
          ...prevState,
          ewayBillDetails,
        }));
        console.log("Data fetched successfully");
      } else {
        const respMsg = data.message || "An error occurred while fetching the data";
        setState((prevState) => ({
          ...prevState,
          basicTitle: respMsg,
          basicType: "danger",
          show: true,
        }));
      }
    } catch (error) {
      console.error("Error fetching waybill data:", error);
      setState((prevState) => ({
        ...prevState,
        basicTitle: `Error fetching waybill data: ${error.message || "Unknown error"}`,
        basicType: "danger",
        show: true,
      }));
    }
  };

  const handleGoClickTransporter = async () => {
    if (!transporterId) {
      setState((prevState) => ({
        ...prevState,
        basicTitle: "Please enter a valid Transporter Id",
        basicType: "danger",
        show: true,
      }));
      return;
    }

    const formData = {
      transporterId,
      eway_bill_number: ewayBillNo,
      tpt_code: (() => {
        try {
          const transporterCode = localStorage.getItem("transportercode");
          return transporterCode ? JSON.parse(transporterCode)[0] : null;
        } catch (e) {
          console.error("Error parsing transporter code from localStorage:", e);
          return null;
        }
      })(),
    };

    const { tptDetails } = state;

    if (Array.isArray(tptDetails) && tptDetails.length > 0) {
      formData.gstin_of_generator = tptDetails[0].gstin;
      formData.transporter_name = tptDetails[0].transporter_name;
    }
    try {
      const response = await redirectURL.post(
        "/consignments/updateEwaybillTransporterId",
        formData
      );
      const data = response.data;
      if (data.code === 200 || data.code === "200") {
        const respMsg = "Transporter Id updated successfully";
        console.log(data, respMsg, "---123")
        setState((prevState) => ({
          ...prevState,
          basicTitle: respMsg,
          basicType: "success",
          show: true,
        }));
        console.log("Transporter Id updated successfully");
      } else {
        const respMsg = data.message || "An error occurred while updating the Transporter Id";
        setState((prevState) => ({
          ...prevState,
          basicTitle: respMsg,
          basicType: "danger",
          show: true,
        }));
      }
    } catch (error) {
      console.error("Error while updating the Transporter Id:", error);
      setState((prevState) => ({
        ...prevState,
        basicTitle: `Error while updating the Transporter Id: ${error.message || "Unknown error"}`,
        basicType: "danger",
        show: true,
      }));
    }
  };

  const closeAlert = () => {
    setState((prevState) => {
      const newState = {
        ...prevState,
        show: false,
      };
      if (prevState.basicTitle === "Transporter Id updated successfully") {
        window.location.href = "/tptlogin/updatetransporterewaybillrequests";
        // Reset the state values
        newState.ewayBillDetails = [];
        newState.ewayBillNo = "";
        newState.transporterId = "";
        // Update the ewayBillNo state to reset the input value
        setEwayBillNo(""); // This will reset the value of the input field
        // No need for jQuery manipulation
      }
      return newState;
    });
  };

  return (
    <div className="container-fluid">
      {state.show && (
        <SweetAlert
          show={state.show}
          type={state.basicType}
          title={state.basicTitle}
          onConfirm={closeAlert}
        />
      )}

      <div style={{ textAlign: "center", marginBottom: "20px" }}>
        <h4>UPDATE TRANSPORTER DETAILS OF E-WAY BILL</h4>
        <div style={{ marginTop: "20px" }}>
          <label htmlFor="ewayBillNo">E-WAY BILL NO:</label>
          <input
            type="text"
            id="ewayBillNo"
            value={ewayBillNo}
            onChange={(e) => setEwayBillNo(e.target.value)}
            style={{ margin: "0 10px", padding: "5px" }}
          />
          <button
            onClick={handleGoClick}
            style={{
              backgroundColor: "#6c63ff",
              color: "#fff",
              padding: "5px 10px",
              border: "none",
              cursor: "pointer",
            }}
          >
            Go
          </button>
        </div>
      </div>
      {Array.isArray(state.ewayBillDetails) && state.ewayBillDetails.length > 0 ? (
        <div style={{ marginTop: "20px" }}>
          <EbillWayDetails ebillInfo={state.ewayBillDetails[0]} />

          <div style={{ textAlign: "left", padding: "2% 0 5% 0" }}>
            <h4>Transporter Details</h4>
            <label htmlFor="transporterId">Transporter Id:</label>
            <input
              type="text"
              id="transporterId"
              value={transporterId}
              onChange={(e) => setTransporterId(e.target.value)}
              style={{ margin: "0 10px", padding: "5px" }}
            />
            <button
              onClick={handleGoClickTransporter}
              style={{
                backgroundColor: "#6c63ff",
                color: "#fff",
                padding: "5px 10px",
                border: "none",
                cursor: "pointer",
              }}
            >
              Submit
            </button>
          </div>
        </div>
      ) : null}

    </div>
  );
};

export default UpdateEwaybillTransporter;
