import React, { Component } from 'react';
import ReactDOM from 'react-dom';
import SweetAlert from 'react-bootstrap-sweetalert';
import axios from 'axios';
import { AgGridReact } from '@ag-grid-community/react';
import { AllModules } from '@ag-grid-enterprise/all-modules';

import "@ag-grid-enterprise/all-modules/dist/styles/ag-grid.css";
import "@ag-grid-enterprise/all-modules/dist/styles/ag-theme-balham.css";
import { getHyphenDDMMMYYYYHHMM, getHyphenDDMMYYYYHHMMSS } from '../common/utils';


var redirectURL = require('../redirectURL');
var moment = require('moment');

export default class UpdateTransporterEwayBillRequests extends Component {

	constructor(props) {
		super(props);
		this.displayData = [];
		this.state = {			
            loadshow: 'show-n',
            overly: 'show-n',
			show: false,
			basicTitle: '',
			statusBar: {
				statusPanels: [
					{ statusPanel: "agTotalAndFilteredRowCountComponent", align: "left" },
					{ statusPanel: "agTotalRowCountComponent", align: "center" },
					{ statusPanel: "agFilteredRowCountComponent" },
					{ statusPanel: "agSelectedRowCountComponent" },
					{ statusPanel: "agAggregationComponent" }
				]
			},
			sideBar: {
				toolPanels: [
					{ id: "columns", labelDefault: "Columns", labelKey: "columns", iconKey: "columns", toolPanel: "agColumnsToolPanel" },
					{ id: "filters", labelDefault: "Filters", labelKey: "filters", iconKey: "filter", toolPanel: "agFiltersToolPanel" }
				]
			},
			rowClassRules: {},
			modules: AllModules,  // Required for ag-Grid to work
			defaultColDef: {
				sortable: true,
				filter: true,
				resizable: true
			},
			paginationPageSize: 10,
			frameworkComponents: {},
			detailCellRendererParams: {},
			sliderContent: "",
			bulkslide: "",
			sliderType: 1,
			sidebarSubHeader: "",
			columnwithDefsForSlider: []
		};
	}

	componentDidMount() {
		this.onLoadPageData();
	}

	onLoadPageData() {
		this.setState({ loadshow: 'show-m', overly: 'show-m' });
		var deviceparams = { transporter_code: localStorage.getItem("transportercode") };
		redirectURL.post("/consignments/getUpdateTransporterEwayBillRequests", deviceparams)
			.then(response => {
				var records = response.data;
				this.setState({ rowData: records, loadshow: 'show-n', overly: 'show-n' });
			});
	}

	onGridReady = params => {
		this.gridApi = params.api;
		this.gridColumnApi = params.columnApi;
	};

	onGridState = () => {
		this.colState = this.gridColumnApi.getColumnState();
		this.rowGroupState = this.gridColumnApi.getRowGroupColumns();
		this.pivotColumns = this.gridColumnApi.getPivotColumns();
		window.filterState = this.gridApi.getFilterModel();
		this.gridApi.setFilterModel(window.filterState);
	};

	onClickSaveGridState() {
		window.colState = this.gridColumnApi.getColumnState();
		window.groupState = this.gridColumnApi.getColumnGroupState();
		window.sortState = this.gridApi.getSortModel();
		window.filterState = this.gridApi.getFilterModel();

		let screenpage = 'Hero Trips';
		let reqparams = {
			gridcolumns: window.colState,
			gridgroup: window.groupState,
			gridcolsort: window.sortState,
			gridcolfilter: window.filterState,
			userId: localStorage.getItem("userid"),
			screenurl: window.location.pathname,
			screentitle: screenpage
		};

		redirectURL.post("/consignments/saveGridStates", reqparams)
			.then(response => {
				this.setState({
					show: true,
					basicTitle: "Successfully saved grid layout",
					basicType: "success",
					screenurl: window.location.pathname,
					screentitle: screenpage
				});
			})
			.catch(function (e) {
				console.log("Error ", e);
			});
	}

	restoreGridStates() {
		try {
			if (this.state.usergridstate && this.state.usergridstate.length > 0) {
				var windowstates = this.state.usergridstate;
				this.gridColumnApi.setColumnState(windowstates[0].gridcolumns);
				this.gridColumnApi.setColumnGroupState(windowstates[0].gridgroup);
				this.gridApi.setSortModel(windowstates[0].gridcolsort);
				this.gridApi.setFilterModel(windowstates[0].gridcolfilter);
			}
		} catch (e) {
			console.log("Error restoring grid state", e);
		}
	}

	resetState = () => {
		this.gridColumnApi.resetColumnState();
		this.gridColumnApi.resetColumnGroupState();
		this.gridApi.setSortModel(null);
		this.gridApi.setFilterModel(null);

		var reqparams = {
			userId: localStorage.getItem("userid"),
			screenurl: window.location.pathname,
			screentitle: this.state.screenpage
		};

		redirectURL.post("/consignments/removeUserGridState", reqparams)
			.then((response) => {
				this.setState({
					show: true,
					basicTitle: "Successfully reset default grid layout",
					basicType: "success"
				});
			});
	};

	onCellClicked(e) {
		if (e.colDef.field === "eway_bill_number") {
			if (e.data.update_type === "BULK") {
				let sliderContent = [];
				
				const columnwithDefsForSlider1 = [
					{
						headerName: "E Way Bill No",
						field: "eway_bill_number",
						width: 230,
						resizable: true,
					},
					{
						headerName: "Transporter Id",
						field: "transporter_id",
						width: 230,
						resizable: true,
					}
				];
				try {
					sliderContent = JSON.parse(e.data.input_params).list || [];
				} catch (error) {
					console.error("Error parsing input_params:", error);
					sliderContent = []; // Fallback to empty array if parsing fails
				}
	
				this.setState({
					sliderContent: sliderContent,
					bulkslide: "slider-translate-40p",
					sliderType: 1,
					loadshow: 'show-n',
					sidebarSubHeader: "Input Details - " + e.data.enm_request_id,
					overly: 'show-m',
					columnwithDefsForSlider: columnwithDefsForSlider1
				});
			}
		}
	
		if (e.colDef.field === "response_from_api_python") {
			if (e.data.status === "Success" || e.data.status === "success") {
				const columnwithDefsForSlider2 = [
					{
						headerName: "E Way Bill No",
						field: "ewbNo",
						width: 180,
						resizable: true,
						valueGetter: (params) => {
							const data = params.data.message || params.data; // Use message if present, else fallback to flat data
							const ewayBillNo = data.ewayBillNo;
							return ewayBillNo ? ewayBillNo : "NA"; // Return ewayBillNo if it exists, otherwise return "NA"
						}
					},
					{
						headerName: "Transporter Id",
						field: "transporterId",
						width: 180,
						resizable: true,
						valueGetter: (params) => {
							const data = params.data.message || params.data; // Use message if present, else fallback to flat data
							const transporterId = data.transporterId;
							return transporterId ? transporterId : "NA"; // Return transporterId if it exists, otherwise return "NA"
						}
					},
					{
						headerName: "Transporter Update Date",
						field: "transUpdateDate",
						width: 180,
						resizable: true,
						valueGetter: (params) => {
							const data = params.data.message || params.data; // Use message if present, else fallback to flat data
							const transUpdateDate = data.transUpdateDate;
							return transUpdateDate ? transUpdateDate : "NA"; // Return transUpdateDate if it exists, otherwise return "NA"
						}
					}
				];
			
				let responseResults = [];
				if (e.data.update_type === "BULK") {
					try {
						responseResults = JSON.parse(e.data.response_from_api_python).results || [];
					} catch (error) {
						console.error("Error parsing response_from_api_python:", error);
						responseResults = []; // Fallback to empty array if parsing fails
					}
				} else {
					try {
						responseResults = [e.data.response_from_api] || [];
					} catch (error) {
						console.error("Error parsing response_from_api:", error);
						responseResults = []; // Fallback to empty array if parsing fails
					}
				}
				let sliderContent = [];
				console.log(responseResults,"responseResults-247")
				// Check if there is no content (code 204) or empty data
				if (responseResults.length === 0 || (responseResults[0] && responseResults[0].code === 204 && responseResults.code === 204)) {
					// Handle "No Content" case
					console.log("No records found.");
					sliderContent = []; // Empty content or a message indicating no records
				} else {
					// Handle valid content
					sliderContent = responseResults;
				}
				console.log(sliderContent,"sliderContent-256")
				this.setState({
					sliderContent: sliderContent, // Set sliderContent based on the condition above
					bulkslide: "slider-translate-50p",
					sliderType: 1,
					loadshow: 'show-n',
					sidebarSubHeader: "Response Status - " + e.data.enm_request_id,
					overly: 'show-m',
					columnwithDefsForSlider: columnwithDefsForSlider2
				});
			}			
		}
	}

    onClickHideAll() {
        this.setState({
            loadshow: 'show-n',
            overly: 'show-n',
			sliderContent : "",
			bulkslide:"",
			sliderType:1,
			sidebarSubHeader:"",
			columnwithDefsForSlider:[]
        });

    }

	render() {
		const columnDefs = [
			{
				headerName: "E Way Bill No",
				field: "eway_bill_number",
				width: 150,
				resizable: true,
				cellRenderer: function(params){
					let ewaybillInfo = params.data.input_params;
					if(params.data.update_type == "INDIVIDUAL")
					{
						if (ewaybillInfo && ewaybillInfo !== "") {
							try {
								ewaybillInfo = JSON.parse(ewaybillInfo);
								return ewaybillInfo.eway_bill_number || "NA";
							} catch (error) {
								console.error("Error parsing ewaybillInfo:", error);
								return "Invalid JSON";
							}
						}						
						return "NA";
					}
					else
					{
						var htmloption = '<button class="btn btn-info" style="padding:0 6px 6px">View</button>';
						return htmloption;
					}
				}
			},
			{
				headerName: "Request Id",
				field: "enm_request_id",
				width: 150,
				pinned: "left",
				resizable: true,
			},
			{
				headerName: "Request Type",
				field: "request_type",
				width: 250,
				pinned: "left",
				resizable: true,
				valueGetter: (params) => (params.data.request_type+" "+params.data.update_type)
			},
			{
				headerName: "Status",
				field: "status",
				width: 100,
				pinned: "left",
				resizable: true,
				valueGetter: (params) => (params.data.status === "No Content" ? "Failed" : params.data.status)
			},
			{
				headerName: "Remarks",
				field: "remarks",
				width: 300,
				pinned: "left",
				resizable: true,
				valueGetter: (params) => params.data.status !== "Success" && params.data.status !== "success" && params.data.status !== "Pending" ? params.data.response_from_api : params.data.status
			},
			{
				headerName: "Requested Date",
				field: "requsted_date",
				width: 200,
				resizable: true,
				valueGetter: (params) => params.data.requsted_date ? getHyphenDDMMMYYYYHHMM(params.data.requsted_date) : "NA"
			},
			{
				headerName: "Response Status",
				field: "response_from_api_python",
				width: 150,
				resizable: true,
				cellRenderer: function(params){
					if(params.data.status == "Success" || params.data.status == "success")
					{
						var htmloption = '<button class="btn btn-success" style="padding:0 6px 6px">View</button>';
						return htmloption;	
					}				
					return "NA";
				}
			},
		];	
		return (
			<div className="container-fluid">
				<SweetAlert
					show={this.state.show}
					type={this.state.basicType}
					title={this.state.basicTitle}
					onConfirm={() => this.setState({ show: false })}
				/>

				<div className="row">
					<div className="col-xl-12 col-lg-12">
						<div className="card">
							<div className="card-header">
								<h5>
									<i className="icofont icofont-vehicle-delivery-van cus-i"></i>
									<span>Transporter Update Requests</span>
								</h5>
							</div>
							<div className="card-body pt-10px">
								<div id="myGrid" className="ag-theme-balham" style={{ height: "580px", width: "100%" }}>
									<AgGridReact
										modules={this.state.modules}
										columnDefs={columnDefs}
										defaultColDef={this.state.defaultColDef}
										rowData={this.state.rowData}
										onGridReady={this.onGridReady}
										onGridState={this.onGridState}
										statusBar={this.state.statusBar}
										sideBar={this.state.sideBar}
										paginationPageSize={this.state.paginationPageSize}
										pagination={true}
										frameworkComponents={this.state.frameworkComponents}
										detailCellRendererParams={this.state.detailCellRendererParams}
										enableCellChangeFlash={false}
										rowClassRules={this.state.rowClassRules}
										gridOptions={{
											context: { componentParent: this }
										}}
										masterDetail={true}
										suppressRowClickSelection={true}
										onCellClicked={this.onCellClicked.bind(this)}
									/>
								</div>
							</div>
						</div>
					</div>
					<div className="form-theme col-xl-12 col-lg-12 row">
                        <div className={"slide-r " + (this.state.bulkslide)} style={{ overflow: "auto" }}>
                            <h3 className="subH">
                                {this.state.sidebarSubHeader}                                
                                <span className="float-right fclose" style={{marginRight:"12px",padding:"1px 8px",marginTop:"-2px"}} onClick={this.onClickHideAll.bind(this)}>x</span>
                            </h3>                            
                            <div className="slide-r-body" style={{ position: "relative" }}>
                            <div className="container-fluid">
                                {(this.state.sliderType == 1) ?
                                <div className='row col-sm-12'>
                                    <div id="myGrid" style={{ width: "100%", height: "90vh" }} className={"ag-theme-balham"}>
                                        <AgGridReact
                                            modules={this.state.modules}
                                            columnDefs={this.state.columnwithDefsForSlider}
                                            defaultColDef={this.state.defaultColDef}
                                            rowData={this.state.sliderContent}
                                            enableCharts={false}
                                            // autoGroupColumnDef={this.state.autoGroupColumnDef}
                                            onGridReady={this.onGridReady1}
                                            onGridState={this.onGridState1}
                                            frameworkComponents={this.state.frameworkComponents}
                                            statusBar={this.state.statusBar}
                                            sideBar={this.state.sideBar}
                                            paginationPageSize={this.state.paginationPageSize}
                                            pagination={false}
                                            gridOptions={{
                                                context: { componentParent: this }
                                            }}
                                            // components={this.state.components}
                                            enableRangeSelection={true}
                                        />
                                    </div>
                                </div>
                                :""}
                            </div>
                            </div>
                        </div>

                        <div className={"dataLoadpageimg " + (this.state.loadshow)}>
                            <div className="loader-box"><div className="loader-box"><div className="rotate dashed colored"></div></div></div>
                        </div>

                    </div>
				</div>

				<div className={`dataLoadpage ${this.state.loadshow}`} />
				<div className={`dataLoadpageimg ${this.state.loadshow}`}>
					<div className="loader-box">
						<div className="rotate dashed colored" />
					</div>
				</div>
				<div className={`overlay-part ${this.state.overly}`} onClick={this.onClickHideAll.bind(this)} />
			</div>
		);
	}
}
