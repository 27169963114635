import React, { useState, useEffect, useRef } from "react";
import redirectURL from '../redirectURL';
import SweetAlert from 'react-bootstrap-sweetalert';

const BulkvehicleUpdate = () => {
    // Move `styles` object to the top to ensure it's available when referenced
    const styles = {
        container: {
            width: '55%',
            margin: '20px auto',
            padding: '20px',
            border: '1px solid #ccc',
            borderRadius: '8px',
            textAlign: 'center',
            backgroundColor: '#f9f9f9',
        },
        input: {
            margin: '10px 5px',
            padding: '10px',
            fontSize: '16px',
            borderRadius: '5px',
            border: '1px solid #ccc',
        },
        button: {
            padding: '10px 20px',
            backgroundColor: '#007BFF',
            color: 'white',
            border: 'none',
            borderRadius: '5px',
            fontSize: '16px',
            cursor: 'pointer',
            transition: 'background-color 0.3s ease',
            marginLeft: '6px',
        },
        buttonHover: {
            backgroundColor: '#0056b3',
        },
        errorMessage: {
            color: 'red',
            fontSize: '14px',
            marginTop: '10px',
            fontWeight: 'bold',
        },
    };
    const fileInputRef = useRef(null);
    const [file, setFile] = useState(null);
    const [error, setError] = useState('');
    const [state, setState] = useState({
        tptDetails: [],
        basicTitle: '',
        basicType: '',
        show: false,
        loadshow: 'hide-n',
        overly: 'hide-n'
    });
    useEffect(() => {
        const fetchTransporterDetails = async () => {
            const params = { tpt_code: localStorage.getItem("transportercode") };
            try {
                const response = await redirectURL.post(
                    "/dashboard/getTransporterDetails",
                    params
                );
                if (response?.data?.length > 0) {
                    setState((prevState) => ({
                        ...prevState,
                        tptDetails: response.data,
                    }));
                }
            } catch (error) {
                console.error("Error fetching transporter details:", error);
            }
        };
        fetchTransporterDetails();
    }, []);
    const [buttonStyle, setButtonStyle] = useState(styles.button);

    const handleFileChange = (event) => {
        const uploadedFile = event.target.files[0];

        if (!uploadedFile) {
            setError('Please upload a valid file.');
            return;
        }

        if (uploadedFile.type !== 'application/json') {
            setError('Please upload a valid JSON file.');
            setFile(null);
            // Clear the file input field
            if (fileInputRef.current) {
                fileInputRef.current.value = '';
            }
            return;
        }

        const maxSize = 5 * 1024 * 1024; // 5MB
        if (uploadedFile.size > maxSize) {
            setError('File size exceeds 5MB. Please upload a smaller file.');
            setFile(null);
            // Clear the file input field
            if (fileInputRef.current) {
                fileInputRef.current.value = '';
            }
            return;
        }

        const reader = new FileReader();
        reader.onload = (e) => {
            console.log(e.target.result, "109")
            console.log(JSON.parse(e.target.result), "110")
            try {
                const jsonContent = JSON.parse(e.target.result);

                const handleFileChange = (event) => {
                    const uploadedFile = event.target.files[0];

                    // ... (rest of your validation checks)

                    const reader = new FileReader();
                    reader.onload = (e) => {
                        try {
                            const jsonContent = JSON.parse(e.target.result);

                            // ... (your existing validation logic for missing fields in jsonContent)
                            if (
                                !jsonContent.vehicleUpdts ||
                                !Array.isArray(jsonContent.vehicleUpdts) ||
                                jsonContent.vehicleUpdts.length === 0
                            ) {
                                setError("Missing or invalid required field: vehicleUpdts");
                                setFile(null);
                                return;
                            }

                            for (const entry of jsonContent.vehicleUpdts) {
                                if (!entry.ewbno) {
                                    setError("Missing required field: ewbno");
                                    setFile(null);
                                    return;
                                }
                                if (!entry.transMode) {
                                    setError("Missing required field: transMode");
                                    setFile(null);
                                    return;
                                }
                                if (!entry.fromPlace) {
                                    setError("Missing required field: fromPlace");
                                    setFile(null);
                                    return;
                                }
                                if (!entry.reason) {
                                    setError("Missing required field: reason");
                                    setFile(null);
                                    return;
                                }
                                // Conditional validations based on the screenshot
                                if (entry.transMode === 1) { // If Trans Mode is Road (assuming 1 represents Road)
                                    if (!entry.vehicleType) {
                                        setError("Missing required field: vehicleType (when Trans Mode is Road)");
                                        setFile(null);
                                        return;
                                    }
                                    if (!entry.vehicleNo && !entry.docNo) { //If Transld is not entered
                                        setError("Missing required field: vehicleNo or docNo (when Trans Mode is Road and Transld is not entered)");
                                        setFile(null);
                                        return;
                                    }
                                } else if (entry.transMode === 2 || entry.transMode === 3) { // If Trans Mode is Rail/Air/Ship (assuming 2 and 3 represent these)
                                    if (!entry.docNo) {
                                        setError("Missing required field: docNo (when Trans Mode is Rail/Air/Ship)");
                                        setFile(null);
                                        return;
                                    }
                                    if (!entry.docDate) {
                                        setError("Missing required field: docDate (when Trans Mode is Rail/Air/Ship)");
                                        setFile(null);
                                        return;
                                    }
                                }
                                if (entry.reason === 5) { // If Reason for Updation is Others (assuming 5 represents Others)
                                    if (!entry.remark) {
                                        setError("Missing required field: remark (when Reason for Updation is Others)");
                                        setFile(null);
                                        return;
                                    }
                                }
                            }
                            // If all validations pass
                            console.log("File Passed");
                            setFile(uploadedFile);
                            // Clear the file input field
                            if (fileInputRef.current) {
                                fileInputRef.current.value = '';
                            }
                            setError("");
                        } catch (err) {
                            if (err instanceof SyntaxError) {
                                setError("Invalid JSON structure.");
                            } else {
                                console.error("Unexpected error:", err);  // Log for debugging other errors
                            }
                            setFile(null);
                            // Clear the file input field
                            if (fileInputRef.current) {
                                fileInputRef.current.value = '';
                            }
                        } finally {
                            // Always clear the file input field
                            if (fileInputRef.current) {
                                fileInputRef.current.value = '';
                            }
                        }
                    };

                    reader.readAsText(uploadedFile);
                };

                // If all validations pass
                console.log("File Passsed")
                setFile(uploadedFile);
                setError("");
            } catch (err) {
                setError("Invalid JSON structure.");
                setFile(null);
                // Clear the file input field
                if (fileInputRef.current) {
                    fileInputRef.current.value = '';
                }
            }
        };


        reader.readAsText(uploadedFile);
    };

    const handleSubmit = (event) => {
        event.preventDefault();
        if (!file) return;
        var transportercode = JSON.parse(localStorage.getItem("transportercode"));

        const formData = new FormData();
        formData.append('jsonFile', file);
        formData.append('tpt_code', transportercode[0]);
        const tptDetails = state.tptDetails;
        if (Array.isArray(tptDetails) && tptDetails.length > 0) {
            formData.append('gstin_of_generator', tptDetails[0].gstin);
            formData.append('transporter_name', tptDetails[0].transporter_name);
        }
        redirectURL
            .post('/consignments/bulkUploadEWBvehicle', formData)
            .then((response) => {
                const data = response.data;
                var respMsg = "";
                var basicType = "";
                if (data.status == 'Success') {
                    respMsg = 'File uploaded and processed successfully';
                    basicType = "success"
                } else {
                    respMsg = data.message || 'An error occurred while uploading the file';
                    basicType = "error"
                }
                setState({
                    basicTitle: respMsg,
                    basicType: basicType,
                    show: true,
                    loadshow: 'show-n',
                    overly: 'show-n'
                });
                setFile(null);
                // Clear the file input field
                if (fileInputRef.current) {
                    fileInputRef.current.value = '';
                }
            })
            .catch((error) => {
                console.error('Error:', error);
                setState({
                    basicTitle: 'An error occurred while uploading the file',
                    basicType: 'error',
                    show: true,
                    loadshow: 'show-n',
                    overly: 'show-n'
                });
                setFile(null);
                // Clear the file input field
                if (fileInputRef.current) {
                    fileInputRef.current.value = '';
                }
            });
    };
    const closeAlert = () => {
        setState({ show: false });
        // Uncomment the following line if you want to redirect:
        // window.location.href = "/tptlogin/ewaybillrequests";
    };

    const handleMouseEnter = () => setButtonStyle({ ...styles.button, ...styles.buttonHover });
    const handleMouseLeave = () => setButtonStyle(styles.button);

    return (
        <div className="container-fluid">
            {state.show && ( // Render SweetAlert only if show is true
                <SweetAlert
                    show={state.show}
                    type={state.basicType}
                    title={state.basicTitle}
                    onConfirm={closeAlert}
                />
            )}
            <div style={styles.container}>
                <h6>Update vehicle Details - Bulk Upload</h6>
                <form onSubmit={handleSubmit}>
                    <label>UPLOAD JSON FILE (MAX 5MB)</label>
                    <input
                        type="file"
                        accept=".json"
                        ref={fileInputRef}
                        onChange={handleFileChange}
                        style={styles.input}
                    />
                    {error && <div style={styles.errorMessage}>{error}</div>}
                    <button
                        type="submit"
                        style={buttonStyle}
                        onMouseEnter={handleMouseEnter}
                        onMouseLeave={handleMouseLeave}
                    >
                        Upload & Generate
                    </button>
                </form>
            </div>
        </div>
    );
};

export default BulkvehicleUpdate;